<template>
  <BodyHeading v-if="$route.name.includes('Details')" :borderColor="'rgb(160, 146, 240)'" :title="$t('order.details')"
    :actionBtnVisibility="true" :multiLanguageDisabled="true" @handleClick="edit"></BodyHeading>
  <div class="page-body-main-create">
    <div class="card-wrapper">
      <div class="card-title">{{ $t('generalInformation') }}</div>
      <div class="card">
        <div class="card-body">
          <!--  -->
          <div class="row" v-if="!isLoading">
            <!--  -->
            <div class="col-lg-2 col-md-3 col-sm-6 mb-2"><span class="fw-bold">{{ $t('order.customerNumber') }}:</span>
            </div>
            <div class="col-lg-4 col-md-3 col-sm-6 mb-3"><span>{{ order.user_id }}</span></div>

            <div class="col-lg-2 col-md-3 col-sm-6 mb-2"><span class="fw-bold">{{ $t('order.uniqueKey') }}:</span>
            </div>
            <div class="col-lg-4 col-md-3 col-sm-6 mb-3"><span>{{ order.payment_intent }}</span></div>
            <!--  -->
            <hr>

            <div class="col-lg-2 col-md-3 col-sm-6 mb-2"><span class="fw-bold">{{ $t('order.customerCompany') }}:</span>
            </div>
            <div class="col-lg-4 col-md-3 col-sm-6 mb-3">
              <span v-if="order.user.company">{{ order.user.company }}</span>
              <span v-else> - </span>
            </div>
              
            <div class="col-lg-2 col-md-3 col-sm-6 mb-2"><span class="fw-bold">{{ $t('order.purchaseDate') }}:</span>
            </div>
            <div class="col-lg-4 col-md-3 col-sm-6 mb-3"><span>{{ dateTime(order.created_at) }}</span></div>
            <hr>
            <!--  -->
            <div class="col-lg-2 col-md-3 col-sm-6 mb-2"><span class="fw-bold">{{ $t('order.customerName') }}:</span>
            </div>
            <div class="col-lg-4 col-md-3 col-sm-6 mb-3"><span>{{ order.user.first_name + ' ' +
              order.user.last_name }}</span></div>

            <div class="col-lg-2 col-md-3 col-sm-6 mb-2"><span class="fw-bold">{{ $t('order.orderNumber') }}:</span>
            </div>
            <div class="col-lg-4 col-md-3 col-sm-6 mb-3"><span>{{ 'SB'+(order.invoice_id+50000)}}</span></div>
            <hr>
            <!--  -->
            <div class="col-lg-2 col-md-3 col-sm-6 mb-2"><span class="fw-bold">{{ $t('order.customerGender') }}:</span>
            </div>
            <div class="col-lg-4 col-md-3 col-sm-6 mb-3"><span>{{ (order.user.gender === "male") ? "Herr" : "Frau" }}</span></div>

            <div class="col-lg-2 col-md-3 col-sm-6 mb-2"><span class="fw-bold">{{ $t('order.paidAmount') }}:</span>
            </div>
            <div class="col-lg-4 col-md-3 col-sm-6 mb-3"><span>{{ order.amount_total }} €</span></div>
            <hr>

            <div class="col-lg-2 col-md-3 col-sm-6 mb-2"><span class="fw-bold">{{ $t('order.customerAddress') }}:</span>
            </div>
            <div class="col-lg-4 col-md-3 col-sm-6 mb-3">
              <span>{{ order.billing_country.name + ', ' + order.user.city + ', ' + order.user.address + ', ' +
                order.user.additional_address }}</span>
            </div>

            <div class="col-lg-2 col-md-3 col-sm-6 mb-2"><span class="fw-bold">Versand:</span>
            </div>
            <div class="col-lg-4 col-md-3 col-sm-6 mb-3"><span>{{ postprice }} €</span></div>
            <hr>
            <!--  -->
            <div class="col-lg-2 col-md-3 col-sm-6 mb-2"><span class="fw-bold">{{ $t('order.customerPostcode') }}:</span>
            </div>
            <div class="col-lg-4 col-md-3 col-sm-6 mb-3"><span>{{ order.user.postcode }}</span></div>

            <div class="col-lg-2 col-md-3 col-sm-6 mb-2"><span class="fw-bold">{{ $t('order.ustId') }}:</span></div>
            <div class="col-lg-4 col-md-3 col-sm-6 mb-3">
              <span v-if="order.ust_id">{{ order.ust_id }}</span>
              <span v-else> - </span>
            </div>
            <hr>
            <!--  -->
            <div class="col-lg-2 col-md-3 col-sm-6 mb-2"><span class="fw-bold">{{ $t('order.customerPhone') }}:</span>
            </div>
            <div class="col-lg-4 col-md-3 col-sm-6 mb-3"><span>{{ order.user.phone }}</span></div>


            <!--  -->
            <div class="col-lg-2 col-md-3 col-sm-6 mb-2"><span class="fw-bold">{{ $t('order.coupon') }}:</span></div>
            <div class="col-lg-4 col-md-3 col-sm-6 mb-3">
              <span v-if="order.ust_id">{{ order.coupon_percent }}</span>
              <span v-else> - </span>
            </div>
            <hr>

            <div class="col-lg-2 col-md-3 col-sm-6 mb-2"><span class="fw-bold">{{ $t('order.customerEmail') }}:</span>
            </div>
            <div class="col-lg-4 col-md-3 col-sm-6 mb-3"><span>{{ order.user.email }}</span></div>
            <div class="col-lg-2 col-md-3 col-sm-6 mb-2"><span class="fw-bold">{{ $t('order.csvFile') }}:</span></div>
            <div class="col-lg-4 col-md-3 col-sm-6 mb-3">
              <button class="btn button-action m-0 px-3 py-0" @click="exportCSV(order.payment_intent)">
                <font-awesome-icon :icon="['fas', 'file-download']" class="me-2"></font-awesome-icon> {{
                  $t('order.downloadBtnLabel') }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="card-title">{{ $t('order.orderState') }}</div>
      <div class="card">
        <div class="card-body">
          <div class="row" v-if="!isLoading">
            <div class="col-lg-2 col-md-3 col-sm-6 mb-2"><span class="fw-bold">{{ $t('order.sendUpdateEmail')
            }}:</span></div>
            <div class="col-lg-4 col-md-3 col-sm-6 mb-3">
              <button class="btn button-stop m-0 px-3 py-0" :disabled="order.state === 'canceled'" @click="cancelOrder">
                {{ $t('order.state.cancelled') }}
              </button>
            </div>
            <div v-if="order.confirmed_email_has_sent == 1" class="col-lg-2 col-md-3 col-sm-6 mb-2"><span
                class="fw-bold">{{ $t('order.logs') }}:</span></div>
            <div v-if="order.confirmed_email_has_sent == 1" class="col-lg-4 col-md-3 col-sm-6 mb-3">
              <div v-for="(log, logIndex) in emailLogs" :key="logIndex">{{ `${$t(`order.state.${log.type}`)}
                              ${dateTime(log.sent_at)}` }}</div>
            </div>
            <hr>
            <div v-if="order.state === 'ready' || order.state === 'completed'" class="col-lg-2 col-md-3 col-sm-6 mb-2">
              <span class="fw-bold">{{ $t('order.state.completed') }}</span>
            </div>
            <div v-if="order.state === 'ready' || order.state === 'completed'" class="col-lg-4 col-md-3 col-sm-6 mb-3">
              <v-switch v-model="order.is_complete" @click="completeOrder" :disabled="isSwitchLocked"></v-switch>
            </div>
          </div>
        </div>
      </div>
       -->
      <div class="card-title">{{ $t('order.details') }}</div>
      <div class="card">
        <div class="card-body">
          <div class="mb-4" v-for="(address, index) in addresses" :key="address">
            <div class="bg-light p-3 rounded-1 border border-bottom-0">
              <div>
                <!-- <h6 class="d-inline-block me-3 fw-bold">{{ `${$t('order.address')} ${index + 1} ${$t('order.of')}
                                  ${addresses.length} :` }}</h6> -->
                <p class="d-inline-block mb-2" :key="index"><b>Lieferadresse:</b> {{ address.country_name + ', ' + address.city + ', ' +
                  address.address + ', ' + ( address.additional_address !== null &&  address.additional_address !== undefined &&  address.additional_address !== '' ?  address.additional_address: '') + ' - ' + address.postcode }}</p>
              </div>
              <!-- <div> -->
                <!-- <h6 class="d-inline-block me-3 fw-bold">{{ $t('order.selectedPostMethod') }}:</h6> -->
                <!-- <p class="d-inline-block mb-0">{{ address.post.title }}</p> -->
              <!-- </div> -->
            </div>
            <div class="p-3 border border-top-0" v-if="address.relativeProducts">
              <!-- <ValidateEach v-for="(relativeProduct, i) in address.relativeProducts" :key="relativeProduct" :state="relativeProduct" :rules="rules">
                        <template #default="{ v }"> -->
              <div v-for="(relativeProduct) in address.relativeProducts" :key="relativeProduct" class="row">
                <div class="col-lg-6">
                  <div class="row">
                    <div class="col-lg-5 col-md-6 col-sm-6 mb-2"><span class="fw-bold">{{ $t('order.productName')
                    }}:</span></div>
                    <div class="col-lg-7 col-md-6 col-sm-6 mb-3">
                      <span>{{ relativeProduct?.info?.product_title }}</span>
                    </div>
                    <div class="col-lg-5 col-md-6 col-sm-6 mb-2"><span class="fw-bold">{{ $t('order.productOptions')
                    }}:</span></div>
                    <div class="col-lg-7 col-md-6 col-sm-6 mb-3">
                      <ul class="d-inline-block mb-2 list-unstyled">
                        <li v-for="option in parseOptions(relativeProduct?.info?.options)" :key="option">
                          {{ `- ${option}` }}
                        </li>
                      </ul>
                    </div>
                    <div class="col-lg-5 col-md-6 col-sm-6 mb-2"><span class="fw-bold">{{ $t('order.quantity') }}:</span>
                    </div>
                    <div class="col-lg-7 col-md-6 col-sm-6 mb-3"><span>{{ relativeProduct?.quantity }}</span></div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row">
                    <div class="col-lg-5 col-md-6 col-sm-6 mb-2"><span class="fw-bold">{{ $t('order.price') }}:</span>
                    </div>
                    <div class="col-lg-7 col-md-6 col-sm-6 mb-3"><span>{{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(parseFloat(relativeProduct?.list_price- parseFloat(relativeProduct?.discount_price))) }}</span>
                    </div>
                    <div class="col-lg-5 col-md-6 col-sm-6 mb-2"><span class="fw-bold">MwSt. 19%:</span></div>
                    <div class="col-lg-7 col-md-6 col-sm-6 mb-3">
                      <span v-if="!order.ust_id">{{ ((parseFloat(relativeProduct?.product_price)- parseFloat(relativeProduct?.discount_price) + parseFloat(relativeProduct?.duration_price) + parseFloat(relativeProduct?.services_total_price))*0.19).toFixed(2) }} €</span>
                      <span v-else>0 €</span>
                    </div>
                    <div class="col-lg-5 col-md-6 col-sm-6 mb-2"><span class="fw-bold">{{ $t('order.duration') }}:</span>
                    </div>
                    <div class="col-lg-7 col-md-6 col-sm-6 mb-3"><span>{{ `${relativeProduct?.duration_info?.title} (${relativeProduct?.duration} ${relativeProduct?.duration == 1 ? 'day' : 'days'})` }}</span>
                    </div>
                    <div class="col-lg-5 col-md-6 col-sm-6 mb-2"><span class="fw-bold">{{ $t('order.durationPrice')
                    }}:</span></div>
                    <div class="col-lg-7 col-md-6 col-sm-6 mb-3"><span>{{ relativeProduct?.duration_price }}</span>
                    </div>
                    <div class="col-lg-5 col-md-6 col-sm-6 mb-2"><span class="fw-bold">{{ $t('order.totalPrice')
                    }}:</span></div>
                    <div class="col-lg-7 col-md-6 col-sm-6 mb-3"><b>{{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(parseFloat(relativeProduct?.product_price)) }}</b></div>
                  </div>
                </div>
                <!-- <div class="row" v-if="relativeProduct.images.length">
                  <div class="col-12 mt-4">
                    <h6>Benutzer hochgeladene Dateien:</h6>
                    <div class="d-flex" style="gap: 24px">
                      <div v-for="(image, index) in relativeProduct.images" :key="image" class="d-flex flex-column"
                        style="width: 200px">
                        <a target="_blank" :href="'https://api.sevendisplays.com/storage/invoice/SB'+ (order.invoice_id+50000)+'/' + image.path" download class="btn button-stop ms-0 me-2 mb-2">
                          {{ `Ansicht / Herunterladen ${index + 1} of ${relativeProduct.images.length}` }}
                        </a>
                        <button class="btn button-stop btn-danger btn-red ms-0 me-2 mb-2" style="background: red;color: #fff !important;" @click="deleteImage(image.id)">
                          {{ `Löschen ${index + 1} of ${relativeProduct.images.length}` }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" v-else-if="relativeProduct.number_of_images > 0">
                  <div class="col-12 mt-4">
                    <div class="alert alert-primary" role="alert">
                      {{ $t('order.noImages') }}
                    </div>
                  </div>
                </div> -->
                <div class="row">
                  <div class="col-12">
                    <hr v-if="address.relativeProducts.length - 1 > i">
                  </div>
                </div>
              </div>
              <!-- <div class="row g-0">
                              <div class="col-lg-6 col-9">
                                 <v-input type="number" name="tax" label="order.numberOfImages" v-model="v.number_of_images.$model" :isRequired="true"
                                    :validateError="v.number_of_images && v.number_of_images.$error? v.number_of_images.$errors[0].$message : null"></v-input>
                              </div>
                           </div> -->
              <!-- </template>
                     </ValidateEach> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import BodyHeading from '../../components/BodyHeading.vue';
// import Input from '../../components/Input.vue';
// import Switch from '../../components/Switch.vue'
import useValidate from '@vuelidate/core';
// import { ValidateEach } from '@vuelidate/components';
import exportFromJSON from "export-from-json";
// import { numeric, required } from '@/utilities/i18n-validators.js'

export default {
  inject: ['dayjs'],
  components: {
    BodyHeading,
    // ValidateEach,
    // 'v-input': Input,
    // 'v-switch': Switch
  },
  data() {
    return {
      v$: useValidate(),
      order: {},
      addresses: [],
      // initialAddressesState: [], // to compare the initial image number with new ones and enable save btn if changed
      emailLogs: [],
      postprice: 0,
      isExist: true,
      isLoading: true,
      isSwitchLocked: false,
    }
  },
  computed: {
    currentLanguage() {
      return this.$store.state.locale.selectedLocale
    },
  },
  watch: {
    '$route': {
      immediate: true,
      handler() {
        if (this.$route.name.includes('Details')) {
          this.getInvoice(this.$route.params.id);
          this.getAddresses(this.$route.params.id);
          this.getLogs()
        }
      }
    }
  },
  methods: {
    dateTime(value) {
      return this.dayjs(value).format('YYYY-MM-DD HH:mm')
    },
    parseOptions(options) {
      if (!options) return []; 
      try {
        return [...new Set(JSON.parse(options))];
      } catch (error) {
        console.error("Failed to parse options:", error);
        return []; 
      }
    },
    getInvoice(id) {
      let params = { id: id };
      this.$store.dispatch("order/fetch", params).then(
        (response) => {
          this.order = response;
          this.isExist = true;
          this.isLoading = false;

          this.$store.dispatch("order/get_csv", params).then(
            (responses) => {
              this.postprice = responses?.address[0]?.post_price;          
            }
          );

        },
        () => {
          this.isExist = false;
          this.$router.push({ name: 'orders', query: { page: 1 } })
          this.isLoading = false;
        }
      )
    },
    async getAddresses(id) {
      let params = { id: id };
      const response = await this.$store.dispatch("order/getAddresses", params).catch(err => console.error(err))
      const promiseArray = []
      this.addresses = response.data ? response.data : [];
      this.addresses.forEach((address, index) => {
        promiseArray.push(this.getAddressProducts(address.order_address_id, index))
      })
      await Promise.all(promiseArray).then(() => {
        this.initialAddressesState = structuredClone(this.addresses) // get a copy of addresses
      })
    },
    getAddressProducts(id, index) {
      let relativeProducts = [];
      let params = { id: id };
      return this.$store.dispatch("order/getAddressProducts", params).then(
        (response) => {
          relativeProducts = response.data ? response.data : [];
          this.addresses[index].relativeProducts = []
          relativeProducts.forEach(relativeProduct => {
            this.addresses[index].relativeProducts.push(relativeProduct);
          })
          console.log("🚀 ~ getAddressProducts ~ this.addresses:", this.addresses)
          return Promise.resolve()
        },
        (err) => {
          console.log(err);
        }
      )
    },
    edit() {
      this.v$.$validate()
      if (!this.v$.$error) {
        const paymentIntent = this.$route.params.id
        const products = {}
        this.addresses.forEach(address => {
          address.relativeProducts.forEach(product => {
            products[product.invoice_address_product_id] = product.number_of_images != '' ? product.number_of_images : 0
          })
        })
        this.$store.dispatch("order/setNeededImagesCount", { paymentIntent, body: { products } }).then(
          (data) => {
            this.$notify({
              text: data.message,
              group: 'success'
            });
          },
          (error) => {
            let errorMessage = ''
            for (const err in error) {
              errorMessage += `${error[err]} <br>`;
            }
            this.$notify({
              text: errorMessage,
              group: 'failed'
            });
          }
        )
      }
    },
    createImageLink(product_id, image_id) {
      let params = { product_id, image_id };
      this.$store.dispatch("order/getImage", params).then(
        (response) => {
          var fileURL = `data:image/png;base64,${response}`;
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `${product_id}-${image_id}.png`);
          document.body.appendChild(fileLink);

          fileLink.click();
        },
        (err) => {
          console.log(err);
        }
      )
    },
    deleteImage(imageId) {
      this.$swal().then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("order/deleteImage", imageId).then(
            (message) => {
              this.$notify({
                text: message,
                group: 'success'
              });
              this.getAddresses(this.$route.params.id);
            },
            (error) => {
              let errorMessage = ''
              for (const err in error) {
                errorMessage += `${error[err]} <br>`;
              }
              this.$notify({
                text: errorMessage,
                group: 'failed'
              });
            }
          ) 
        }
      })
    },
    formatDateToGerman(dateString) {
      const date = new Date(dateString);
      const pad = (num) => (num < 10 ? '0' : '') + num;
      const day = pad(date.getDate());
      const month = pad(date.getMonth() + 1);
      const year = date.getFullYear();
      const hours = pad(date.getHours());
      const minutes = pad(date.getMinutes());
      const seconds = pad(date.getSeconds());
      return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
    },
    exportCSV(id){
      const params = { language: this.selectedLocale, id: id };
      this.$store.dispatch("order/get_csv", params).then(
        (response) => {
          console.log("🚀 ~ exportCSV ~ response:", response)
          var list = [];
          var invoice   = response.invoice;
          var address   = response.address;
          var user   = response.user;
          var products  = response.products;
          var custom  = response.custom;
          var deliver = JSON.parse(custom.products)[0].address

          var item = {
            OrderNumber: '',
            // ExternalOrderNumber: '',
            CustomerNumber: '',
            OrderDate: '',
            InvoiceSalutation: '',
            InvoiceTitle: '',	
            InvoiceFirstName: '',
            InvoiceLastname: '',
            InvoiceCompany: '',
            InvoiceCompanyAddition: '',
            InvoiceStreet: '',
            InvoiceStreetAddition: '',
            InvoiceZipCode: '',
            InvoiceCity: '',
            InvoiceCountry: '',
            InvoiceEMail: '',
            InvoicePhoneNumber: '',	
            InvoiceVatNumber: '',
            DeliveryCompany: '',
            DeliveryCompanyAddition: '',
            DeliverySalutation: '',
            DeliveryTitle: '',	
            DeliveryFirstName: '',
            DeliveryLastName: '',
            DeliveryStreet: '',
            DeliveryStreetAddition: '',
            DeliveryZipCode: '',
            DeliveryCity: '',
            DeliveryCountry: '',
            DeliveryEMail: '',	
            DeliveryPhoneNumber: '',
            ArticleNumber: '',
            ShippingMethodeName: '',
            ArticleName: '',
            ArticlePriceNetto: '',
            ArticlePriceVatInPercent: '',
            ArticlePriceDiscount: '',
            ArticleQuantity: '',
            PositionType: 'Artikel',	
            PaymentMethodName: '',
            PaymentAmount: '',
            PaymentDate: '',
          };

          if(address.length <= 1) {
              let invoiceName;
              if(invoice.payment_type === "prePaid") {
                invoiceName = 'Vorkasse (2% Rabatt)';
              } else if(invoice.payment_type === "postPaid") {
                invoiceName = 'Rechnungskauf';
              } else {
                invoiceName = invoice.payment_type;
              }

              for (let i = 0; i < products.length; i++) {
                item.OrderNumber = 'SB'+(invoice.invoice_id+50000);
                // item.ExternalOrderNumber = invoice.invoice_id;
                item.CustomerNumber = 'SK'+user.id;
                item.OrderDate = this.formatDateToGerman(address[0].created_at);
                item.InvoiceSalutation = (user?.gender === "male" ? 'Herr' : 'Frau');
                item.InvoiceTitle = '';
                item.InvoiceFirstName = user.first_name;
                item.InvoiceLastname = user.last_name;
                item.InvoiceCompany = user.company;
                item.InvoiceCompanyAddition = '';
                item.InvoiceStreet = invoice.address;
                item.InvoiceStreetAddition = invoice.additional_address ?? '';
                item.InvoiceZipCode = user.postcode.toString();
                item.InvoiceCity = invoice.city;
                item.InvoiceCountry = invoice.country_name;
                item.InvoiceEMail = user.email;
                item.InvoicePhoneNumber = user.phone;
                item.InvoiceVatNumber = invoice.ust_id ?? '';
                item.DeliveryCompany = deliver.company;
                item.DeliveryCompanyAddition = '';
                item.DeliverySalutation = (deliver.gender === "male" ? 'Herr' : 'Frau');
                item.DeliveryFirstName = deliver.first_name;
                item.DeliveryLastName = deliver.last_name;
                item.DeliveryStreet = deliver.address;
                item.DeliveryStreetAddition = deliver.additional_address || '';
                item.DeliveryZipCode = deliver.postcode.toString();
                item.DeliveryCity = deliver.city;
                item.DeliveryCountry = (deliver.country?.translation?.name) ? deliver.country?.translation?.name: deliver.country?.name;
                item.DeliveryEMail = deliver.email;
                item.DeliveryPhoneNumber = deliver.phone;
                item.DeliveryTitle = '';
                item.ShippingMethodeName = address[0].post?.title;
                item.ArticleNumber = products[i].product.code;	
                item.PaymentMethodName = invoiceName;

                var totalPrice = 0;
                var totalDiscount = 0;
                var totalDiscountArticle = 0;
                var percentDiscount = (address[0].country_id !== 10) ? 19: 0;
                
                if(!isNaN(parseFloat(products[i].total_price))) totalPrice += parseFloat(products[i].total_price);
                if(!isNaN(parseFloat(products[i].services_total_price))) totalPrice += parseFloat(products[i].services_total_price);
                
                if(invoice.coupon_percent !== 0) {
                      totalDiscountArticle += invoice.coupon_percent;
                }

                if(percentDiscount !== 0) {
                    totalDiscount += totalPrice*(percentDiscount/100);
                }
                
                if(!isNaN(parseFloat(products[i].pre_paid_coupon_price))) {
                  totalPrice += parseFloat(products[i].pre_paid_coupon_price);
                }

                if(invoice.payment_type === "prePaid") {
                  totalDiscountArticle += products[i].pre_paid_percent;
                }

                item.ArticleName = products[i].product_title;	
                item.ArticlePriceNetto = totalPrice.toFixed(2);	
                item.ArticlePriceVatInPercent = percentDiscount.toFixed(2);	
                item.ArticlePriceDiscount = totalDiscountArticle;	
                item.ArticleQuantity = products[i].quantity;	
                item.DiscountedArticlePrice = products[i].total_price;	
                item.PositionType = 'Artikel';	

                item.PaymentAmount = invoice.amount_total;	

                var dates = new Date(products[i].created_at).toLocaleString('de-DE');
                item.PaymentDate = dates.split(',').join('');
                item.Comment = (invoice.comments !== null) ? invoice.comments : '';

                list.push(item);

                item = {
                  OrderNumber: '',
                  // ExternalOrderNumber: '',
                  CustomerNumber: '',
                  OrderDate: '',
                  InvoiceSalutation: '',
                  InvoiceTitle: '',	
                  InvoiceFirstName: '',
                  InvoiceLastname: '',
                  InvoiceCompany: '',
                  InvoiceCompanyAddition: '',
                  InvoiceStreet: '',
                  InvoiceStreetAddition: '',
                  InvoiceZipCode: '',
                  InvoiceCity: '',
                  InvoiceCountry: '',
                  InvoiceEMail: '',
                  InvoicePhoneNumber: '',	
                  InvoiceVatNumber: '',
                  DeliveryCompany: '',
                  DeliveryCompanyAddition: '',
                  DeliverySalutation: '',
                  DeliveryTitle: '',	
                  DeliveryFirstName: '',
                  DeliveryLastName: '',
                  DeliveryStreet: '',
                  DeliveryStreetAddition: '',
                  DeliveryZipCode: '',
                  DeliveryCity: '',
                  DeliveryCountry: '',
                  DeliveryEMail: '',	
                  DeliveryPhoneNumber: '',
                  ArticleNumber: '',
                  ShippingMethodeName: '',
                  ArticleName: '',
                  ArticlePriceNetto: '',
                  ArticlePriceVatInPercent: '',
                  ArticlePriceDiscount: '',
                  ArticleQuantity: '',
                  PositionType: 'Artikel',	
                  PaymentMethodName: '',
                  PaymentAmount: '',
                  PaymentDate: '',
                };

              }
              const data = list;
              const fileName =  'SB'+(invoice.invoice_id+50000)+'_'+invoice.created_at;
              const exportType = exportFromJSON.types.csv;
              setTimeout(() => {
                if(data) exportFromJSON({ data, fileName, exportType });
              },3000)

          } else {

              let invoiceName;
              if(invoice.payment_type === "prePaid") {
                invoiceName = 'Vorkasse (2% Rabatt)';
              } else if(invoice.payment_type === "postPaid") {
                invoiceName = 'Rechnungskauf';
              } else {
                invoiceName = invoice.payment_type;
              }

              for (let i = 0; i < address.length; i++) {
                item.OrderNumber = 'SB'+(invoice.invoice_id+50000);
                // item.ExternalOrderNumber = invoice.invoice_id;
                item.CustomerNumber = 'SK'+user.id;
                item.OrderDate = this.formatDateToGerman(address[i].created_at);
                item.InvoiceSalutation = (user?.gender === "male" ? 'Herr' : 'Frau');
                item.InvoiceTitle = '';
                item.InvoiceFirstName = user.first_name;
                item.InvoiceLastname = user.last_name;
                item.InvoiceCompany = user.company;
                item.InvoiceCompanyAddition = '';
                item.InvoiceStreet = invoice.address ?? '';
                item.InvoiceStreetAddition = invoice.additional_address ?? '';
                item.InvoiceZipCode = invoice.postcode.toString();
                item.InvoiceCity = invoice.city;
                item.InvoiceCountry = invoice.country_name;
                item.InvoiceEMail = user.email;
                item.InvoicePhoneNumber = user.phone;
                item.InvoiceVatNumber = invoice.invoice_id ?? '';
                item.DeliveryCompany = address[i].address.company;
                item.DeliveryCompanyAddition = '';
                item.DeliverySalutation = (address[i].address.gender === "male" ? 'Herr' : 'Frau');
                item.DeliveryFirstName = address[i].address.first_name;
                item.DeliveryLastName = address[i].address.last_name;
                item.DeliveryStreet = address[i].address.address;
                item.DeliveryStreetAddition = address[i].address.additional_address;
                item.DeliveryZipCode = address[i].address.postcode.toString();
                item.DeliveryCity = address[i].address.city;
                item.DeliveryCountry = address[i].country_name;
                item.DeliveryEMail = address[i].address.email;
                item.DeliveryPhoneNumber = address[i].address.phone;
                item.DeliveryTitle = '';
                item.ArticleNumber = '';
                item.ShippingMethodeName = address[i].post?.title;
                item.ArticleName = '';
                item.ArticlePriceNetto = '';
                item.ArticlePriceVatInPercent = '';
                item.ArticlePriceDiscount = (address[i].country_id !== 10) ? 19: 0;
                item.ArticleQuantity = '';
                item.PositionType = 'Artikel';
                item.PaymentMethodName = invoiceName;
                item.PaymentAmount = '';
                item.PaymentDate = '';

                for (let j = 0; j < products.length; j++) {
                  if(address[i].order_address_id === products[j].order_address_id) {

                    totalPrice = 0;
                    totalDiscount = 0;
                    totalDiscountArticle = 0;
                    percentDiscount = (address[i].country_id !== 10) ? 19: 0;
                    
                    if(!isNaN(parseFloat(products[j].total_price))) totalPrice += parseFloat(products[j].total_price);
                    if(!isNaN(parseFloat(products[j].services_total_price))) totalPrice += parseFloat(products[j].services_total_price);
                    
                    if(invoice.coupon_percent !== 0) {
                        totalDiscountArticle += invoice.coupon_percent;
                    }

                    if(percentDiscount !== 0) {
                        totalDiscount += totalPrice*(percentDiscount/100);
                    }
                    
                    if(!isNaN(parseFloat(products[j].pre_paid_coupon_price))) {
                      totalPrice += parseFloat(products[j].pre_paid_coupon_price);
                    }

                    if(invoice.payment_type === "prePaid") {
                      totalDiscountArticle += products[j].pre_paid_percent;
                    }

                    item.ArticleNumber = products[j].product.code;	
                    item.ArticleName = products[j].product_title;	
                    item.ArticlePriceNetto = totalPrice.toFixed(2);	
                    item.ArticlePriceVatInPercent = percentDiscount;	
                    item.ArticlePriceDiscount = totalDiscountArticle;	
                    item.ArticleQuantity = products[j].quantity;	
                    item.DiscountedArticlePrice = products[j].total_price;	
                    item.PositionType = 'Artikel';	
                    item.PaymentAmount = parseFloat(totalPrice)+totalDiscount;	
                    var datep = new Date(products[j].created_at).toLocaleString('de-DE');
                    item.PaymentDate = datep.split(',').join('');
                  }
                }

                item.Comment = (invoice.comments !== null) ? invoice.comments : '';

                list.push(item);
                item = {
                  OrderNumber: '',
                  // ExternalOrderNumber: '',
                  CustomerNumber: '',
                  OrderDate: '',
                  InvoiceSalutation: '',
                  InvoiceTitle: '',	
                  InvoiceFirstName: '',
                  InvoiceLastname: '',
                  InvoiceCompany: '',
                  InvoiceCompanyAddition: '',
                  InvoiceStreet: '',
                  InvoiceStreetAddition: '',
                  InvoiceZipCode: '',
                  InvoiceCity: '',
                  InvoiceCountry: '',
                  InvoiceEMail: '',
                  InvoicePhoneNumber: '',	
                  InvoiceVatNumber: '',
                  DeliveryCompany: '',
                  DeliveryCompanyAddition: '',
                  DeliverySalutation: '',
                  DeliveryTitle: '',	
                  DeliveryFirstName: '',
                  DeliveryLastName: '',
                  DeliveryStreet: '',
                  DeliveryStreetAddition: '',
                  DeliveryZipCode: '',
                  DeliveryCity: '',
                  DeliveryCountry: '',
                  DeliveryEMail: '',	
                  DeliveryPhoneNumber: '',
                  ArticleNumber: '',
                  ShippingMethodeName: '',
                  ArticleName: '',
                  ArticlePriceNetto: '',
                  ArticlePriceVatInPercent: '',
                  ArticlePriceDiscount: '',
                  ArticleQuantity: '',
                  PositionType: 'Artikel',	
                  PaymentMethodName: '',
                  PaymentAmount: '',
                  PaymentDate: '',
                };
              }

              const data = list;
              const fileName =  'SB'+(invoice.invoice_id+50000)+'_'+invoice.created_at;
              const exportType = exportFromJSON.types.csv;
              setTimeout(() => {
                if(data) exportFromJSON({ data, fileName, exportType });
              },3000)
          }
        }
      );
    },  
    downloadCSV(filename) {
      this.$store.dispatch('order/downloadCSV', filename).then(blob => {
        const url = window.URL.createObjectURL(blob.data);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
    },
    completeOrder() {
      this.isSwitchLocked = true
      this.$store.dispatch('order/completeOrder', this.order.payment_intent).then(
        (message) => {
          this.$notify({
            text: message,
            group: 'success'
          });
          this.getInvoice(this.$route.params.id)
        },
        (error) => {
          let errorMessage = ''
          for (const err in error) {
            errorMessage += `${error[err]} <br>`;
          }
          this.$notify({
            text: errorMessage,
            group: 'failed'
          });
        }
      ).finally(() => this.isSwitchLocked = false)
    },
    cancelOrder() {
      this.$store.dispatch('order/cancelOrder', this.order.payment_intent).then(
        (message) => {
          this.$notify({
            text: message,
            group: 'success'
          });
          this.getInvoice(this.$route.params.id)
          this.getLogs()
        },
        (error) => {
          let errorMessage = ''
          for (const err in error) {
            errorMessage += `${error[err]} <br>`;
          }
          this.$notify({
            text: errorMessage,
            group: 'failed'
          });
        }
      )
    },
    getLogs() {
      this.$store.dispatch("order/getLogs", this.$route.params.id).then(
        (response) => {
          this.emailLogs = response.sort((a, b) => a > b ? -1 : 1)
        },
        (error) => {
          let errorMessage = ''
          for (const err in error) {
            errorMessage += `${error[err]} <br>`;
          }
          this.$notify({
            text: errorMessage,
            group: 'failed'
          });
        }
      )
    }
  }
}
</script>
